import React from "react";

import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";

import BulletLinkBlock from "../bulletLinkBlock";
import Constructor from "../constructor";
import LabelLinkBlock from "../labelLinkBlock";
import LinkBlock from "../linkBlock";
import { LinkUrlPrefixEnum } from "../types";
import { getLinkBlockObject, shouldLinkBeBullet } from "../utils";

import { GoroskopProps } from "./types";

import "../styles.scss";
import "./styles.scss";

const Goroskop = ({ pageContext }: GoroskopProps) => {
  const {
    seoId,
    title = "Статья на Lunaro",
    ogTitle,
    description = "Статья на Lunaro",
    textTop,
    textMiddle,
    textBottom,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    goroskopLinks,
    autolinkedGoroskopLinks,
    url,
    expertsInitial,
    zone,
    commonTextTopBottomZone,
    commonTextMiddleBottomZone,
    commonBottomZone,
  } = pageContext;

  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={`${textTop}${textMiddle}${textBottom}`?.match(/src\s*=\s*"(.+?)"/)?.[1]}
      />
      <div className="bg--white goroskop">
        <div className="page-width">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Goroskop}
          />
          <h1 itemProp="headline" className="goroskop__headline">{header || ""}</h1>
          {!!textTop && (
            <div
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(textTop || ""),
              }}
            />
          )}
          <Constructor zone={commonTextTopBottomZone} expertsInitial={expertsInitial} />
          {!!textMiddle && (
            <div
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(textMiddle || ""),
              }}
            />
          )}
          <Constructor zone={commonTextMiddleBottomZone} expertsInitial={expertsInitial} />
          {!!goroskopLinks?.length && (
          <LinkBlock
            links={getLinkBlockObject(goroskopLinks, LinkUrlPrefixEnum.Goroskop)}
          />
          )}
          {!!textBottom && (
            <div
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(textBottom || ""),
              }}
            />
          )}
          <Constructor zone={zone} expertsInitial={expertsInitial} />
          <Constructor zone={commonBottomZone} expertsInitial={expertsInitial} />
          {!!autolinkedGoroskopLinks?.length && (
          <>
            <h2 className="goroskop__more">Смотрите ещё</h2>
            {shouldLinkBeBullet(autolinkedGoroskopLinks?.length ?? 0) ? (
              <BulletLinkBlock
                links={autolinkedGoroskopLinks}
                urlPrefix={LinkUrlPrefixEnum.Goroskop}
              />
            ) : (
              <LabelLinkBlock
                links={autolinkedGoroskopLinks}
                urlPrefix={LinkUrlPrefixEnum.Goroskop}
              />
            )}
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default Goroskop;
